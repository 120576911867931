<script lang="ts" setup>
withDefaults(
  defineProps<{
    isActive?: boolean
    isDisabled?: boolean
    time: string | null
  }>(),
  { isActive: false, isDisabled: false }
)

const emit = defineEmits<{
  (e: 'selected'): void
}>()
</script>

<template>
  <a
    class="
      bg-white font-medium font-regular p-1.5 text-dark text-sm rounded-xl
      border border-solid border-transparent
      swiper-slide w-min mr-2
      !no-underline
      sm:p-2
      md:py-2.5 md:px-3
    "
    href="#"
    :class="{ '!border-green': isActive, '!text-[#D1D1D1] cursor-default pointer-events-none': isDisabled }"
    @click.prevent="emit('selected')"
  >
    {{ time }}
  </a>
</template>
